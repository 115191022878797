<template>
    <div class="sidebar-menu">
        <nav class="desktop-menu">
            <ul class="menu" >
              <li class="menu-item current-menu-item"><router-link to="/"><i class="fas fa-home"></i><span>الصفحة الرئيسية </span></router-link></li>
              <template v-for="publish in categor" :key="publish.id">
                <li class="menu-item pointer" v-if="publish.type == 1">
                <a @click.prevent="GoToSer(publish)">
                  <i class="fas fa-microphone-alt"></i>
                  <span>{{ publish.name_ar }}</span>
                </a>
              </li>
              <li class="menu-item pointer" v-else><a @click="GoToVideo(publish)"><i class="fab fa-youtube"></i><span>{{ publish.name_ar }}</span></a></li>
              </template>
              
                <!-- <li class="menu-item"><router-link :to="{ name: 'SeriesContent', params: { series_id: 1 }}"><i class="fas fa-microphone-alt"></i><span>مفردات اللغة</span></router-link></li>
                <li class="menu-item"><router-link :to="{ name: 'SeriesContent', params: { series_id: 2 }}"><i class="fas fa-microphone-alt"></i><span>التعابير والعبارات</span></router-link></li>  -->
              <!-- <li class="menu-item"><router-link to="/Speaking"><i class="fab fa-youtube"></i><span>تحدث الإنجليزية</span></router-link></li> -->
              <template v-if="showHeader">
              <li class="menu-item pointer"><router-link to="/Unsubscribe"><i class="fas fa-tag"></i><span>إلغاء الاشتراك</span></router-link></li>
              </template>
              <template v-else>
              <li class="menu-item pointer"><router-link to="/Login" ><i class="fas fa-tag"></i><span>تسجيل الدخول</span></router-link></li>
              <li class="menu-item pointer"><a @click="gotoDSP()" ><i class="fas fa-tag"></i><span>إشتراك </span></a></li>
              </template>
            </ul>
        </nav>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { watchEffect } from '@vue/runtime-core';
import { HTTPDSP } from '@/Api/http-dsp';
import { HTTP } from '@/Api/http-common';
import { useRoute, useRouter } from 'vue-router';
import { useCookie } from 'vue-cookie-next'
export default {
  name:'AppSideBar',
  setup() {
       const router = useRouter();
        const route = useRoute();
        const cookie = useCookie()
        const showHeader = ref(true);
        const search = ref([]);
        const categor = ref([]);

    watchEffect(() => {
            if (cookie.isCookieAvailable("msisdn")) {
                HTTPDSP.post("DSPCheckLogin.php?msisdn="+cookie.getCookie("msisdn")).then((res) => {
                    if (res.data.status == 1 ) {
                        return;
                    } else {
                        cookie.removeCookie('msisdn');
                    }
                });
                showHeader.value = true;
            } else {
               showHeader.value = false;
            }
        }); 
        
      try {
        HTTP.get(`GetAllCourses.php`).then((res) => {
          categor.value = res.data.Courses; 
        });
      } catch (err) {
        console.log(err);
      }

      const GoToSer = (publish) => {
          router.push({ name: "SeriesContent", params: { series_id: publish.id } });
      };

      const GoToVideo = (publish) => {
          router.push({ name: "speaking", params: { cat_id: publish.id } });
      };

      const SearchData = () => {
        router.push({ name: "Searchs", params: { search_id: search.value } });
      }

      const gotoDSP = () => {
            window.open("https://dsplp.sd.zain.com/?p=2847968194", "_self");
            // router.push({ name: "Subscribe" });
        }

      return { search, categor, showHeader, SearchData, GoToSer, GoToVideo, gotoDSP };
  },
}
</script>

<style>
.pointer {
  cursor: pointer;
}
</style>