<template>
        <footer class="site-footer">
            <div class="container" style="padding-right: 0;padding-left: 0;"></div>
            <div class="copyright-bar">
                <svg style="fill:#004d4d;margin-bottom: -10px;" viewBox="0 0 500 34" preserveAspectRatio="xMidYMin meet" class="mtn-curve__concave mtn-curve__curve-mobile">
                    <path d="M0,0 C76.4327922,21.6962525 157.300642,32.5443787 242.60355,32.5443787 C327.906458,32.5443787 413.705275,21.6962525 500,0 L500,34 L0,34 L0,0 Z" class="mtn-svg-fill--yellow"></path>
                </svg>
                <div class="row" style="margin-right:0;margin-left:0;background:#004d4d;justify-content: center;padding-top: 15px;">
                    <div class="col-6 col-sm-6 col-lg-6 col-xl-3" style="justify-content: center;display: flex;">
                        <a>
                            <img  style="width: 125px; max-width: 200px;" class="img-fluid" src="../../assets/images/logoSvg.svg" title="appstore" alt="appstore">
                        </a>
                    </div>
                    <div class="col-6 col-sm-6 col-lg-6 col-xl-3" style="justify-content: center;display: flex;">
                        <a >
                            <!-- <img style="max-width: 150px;margin-top: 5px;" class="img-fluid" src="../../assets/images/CR_logo.png" title="appstore" alt="appstore"> -->
                            English Learning
                        </a>
                    </div>
                </div>
                <div class="col-sm-12 text-center" style="background: #004d4d;padding: 20px 0;">
                    <p style="text-align: center;">powered by Ecell © 2024</p>
                </div>
            </div>
        </footer>
        <div id="backtotop" style="display: block;"><i class="fal fa-lg fa-arrow-up"></i></div>
</template>

<script>
export default {
   name: 'AppFooter'
}
</script>

<style>

</style>