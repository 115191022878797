<template>
    <app-header/>
        <div class="container-fluid p-0">
            <div class="d-flex topspace-90" dir="rtl">
                <app-side-bar/>
                <Suspense>
                    <template #default>
                        <app-home/>
                    </template>
                    <template #fallback>
                        <app-loading/>
                    </template>
                </Suspense>
            </div>
        </div>
    <app-footer/>
</template>

<script>
import AppFooter from '../components/layout/AppFooter.vue'
import AppHeader from '../components/layout/AppHeader.vue'
import AppSideBar from '../components/layout/AppSideBar.vue'
import AppLoading from '../components/Spinners/AppLoading.vue'
import { defineAsyncComponent, Suspense } from "vue";
const AppHome = defineAsyncComponent(() =>
  import("../components/Pages/AppHome.vue")
);
export default {
  name: 'Home',
  components: {
    AppHeader,
    AppFooter,
    AppHome,
    AppSideBar,
    AppLoading,
    Suspense
  }
}
</script>
